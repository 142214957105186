// API地址
const apiUrl = 'https://api.hwt6api.top/';
// 域名
const Domain = '//localhost:8080/socket';
// 周期
const Area_Tras = 7;
// 间隔
const Area_interval = 30;
// 间隔单位
const Area_Unit = 'minute';

const App_Url = "https://m.hwtad.top"

const Pc_Url = 'https://pc.hwtad.top '

export const ServiceUrl = 'https://ai-yun.chat/kefu/66efe56d7daac'

export default {
    apiUrl,Domain,Area_Tras,Area_interval,Area_Unit,App_Url,Pc_Url
}
