import { init } from 'klinecharts'
import { useRouter } from 'vue-router';
import {ref} from "vue";
import Server from "@/Axios/index.ts";
import config from "../../config.ts";
import store from "@/assets/store/store.ts";
import BTC from "@/assets/Data/BTC.json";
import DEL from "@/assets/Data/DEL.json";
import ETH from "@/assets/Data/ETH.json";
import LTC from "@/assets/Data/LTC.json";
import XAU from "@/assets/Data/XAU.json";
import USO from "@/assets/Data/USO.json";
import ABNB from "@/assets/Data/ABNB.json";
import BA from "@/assets/Data/BA.json";
import GM from "@/assets/Data/GM.json";
import GOOG from "@/assets/Data/GOOG.json";
import GS from "@/assets/Data/GS.json";
import IBM from "@/assets/Data/IBM.json";
import MCD from "@/assets/Data/MCD.json";
import Tesla from "@/assets/Data/Tesla.json";
import XAGUSD from "@/assets/Data/XAGUSD.json";
import SPX500 from "@/assets/Data/SPX500.json";
import US30 from "@/assets/Data/US30.json";
import NAS100 from "@/assets/Data/NAS100.json";
import APPL from "@/assets/Data/APPL.json";

var timeoutId;
//获取首页数据
async function getAreaData(_type, page, limit, cancelTokenSource) {
    console.log(cancelTokenSource.token)
    let Arr = [];
    if (_type == "") {
        Arr = await Server.post(
        "api/product/list",
        {
            type: "",
            per_page: limit,
            page: page,
        },
        { cancelToken: cancelTokenSource.token }
        );
    } else {
        Arr = await Server.post(
        "api/product/list",
        {
            type: _type,
            per_page: limit,
            page: page,
        },
        { cancelToken: cancelTokenSource.token }
        );
    }

   if (Arr && Arr.data) {
        setTimeout(() => {
        ViveAreaData(Arr.data, limit, page, cancelTokenSource);
        }, 200);
        
        return Arr.data;
   }

}

// 获取面积图数据
async function ViveAreaData(Arr, limit, page, cancelTokenSource) {
  for (
    var i = Number(limit) * Number(page - 1);
    i < Number(limit) * Number(page);
    i++
  ) {
    let index = i - Number(limit) * Number(page - 1);
    if (!Arr[index]) {
      return;
    }
    const data = {
        ticker: Arr[index].symbol,
      multiplier: config.Area_interval,
      timespan: config.Area_Unit,
      from_date: GetDateByTras(),
      to_date: GetDate(),
      sort: "asc",
    };
    ViveAreaLine(
      "k-line-chart" + (i + 1),
      data,
      Arr[index].close >= Arr[index].open ? 0 : 1,
      cancelTokenSource
    );
  }
}

// 绘画面积图
async function ViveAreaLine(_class, _data, state, cancelTokenSource) {
  let controller = new AbortController();
  const Arrs = await Server.post("/api/polygon/getAggregates", _data, {
    cancelToken: cancelTokenSource.token,
  });
  let parent = document.getElementById(_class);
  if (parent) {
    let children = parent.getElementsByClassName("van-loading");
    for (let i = 0; i < children.length; i++) {
      let child = children[i];
      if (child.parentNode === parent) {
        // 确保是直接子元素
        child.style.display = "none";
      }
    }
  }
  try {
    let chart = init(_class);

    chart.setStyles({
      grid: {
        show: false,
      },
      xAxis: {
        show: false,
      },
      yAxis: {
        show: false,
        crosshair: {
          show: false,
          horizontal: {
            show: false,
            line: {
              show: false,
            },
          },
        },
      },
      crosshair: {
        show: false,
      },
      technicalIndicator: {
        line: {
          show: true,
          line: { color: "blue" },
        },
        tooltip: {
          showRule: "none",
        },
      },
      candle: {
        type: "area",
        tooltip: {
          showRule: "none", // 隐藏提示信息
        },
        // 面积图
        area: {
          lineSize: state == 0 ? 3 : 1,
          lineColor: state == 0 ? "#4a90e2" : "red",
          value: "close",
          smooth: true,
          // 33, 150, 243
          backgroundColor: [
            {
              offset: 0,
              color: state == 0 ? "#e1eeff" : "rgba(255, 39, 39, 0.01)",
            },
            {
              offset: 1,
              color: state == 0 ? "#e1eeff" : "rgba(255, 39, 39, 0.2)",
            },
          ],
          point: {
            show: false,
          },
          style: {
            line: {
              style: "none",
            },
          },
        },
        priceMark: {
          show: false,
        },
      },
      tooltip: {
        showRule: "none", // 隐藏提示信息
      },
      shape: {
        line: {
          style: "solid",
          color: "#2196F3",
          size: 1,
          dashValue: [2, 2],
        },
      },
    });

    // chart.setStyles({
    //     candle:{
    //         area:{
    //             backgroundColor
    //         }
    //     }
    // });



    let KLArrData = Arrs;
    // console.log(_data.ticker);
    if (KLArrData.length == 0){
        switch (_data.ticker) {
            case "BTCUSDT":
                KLArrData = BTC;
                break;
            case "ETHUSDT":
                KLArrData = ETH;
                break;
            case "LTCUSDT":
                KLArrData = LTC;
                break;
            case "XAUUSD":
                KLArrData = XAU;
                break;
            case "USOIL":
                KLArrData = USO;
                break;
            case "DELL":
                KLArrData = DEL;
                break;
            case "ABNB":
                KLArrData = ABNB;
                break;
            case "BA":
                KLArrData = BA;
                break;
            case "APPL":
                KLArrData = APPL;
                break;
            case "GM":
                KLArrData = GM;
                break;
            case "GOOG":
                KLArrData = GOOG;
                break;
            case "GS":
                KLArrData = GS;
                break;
            case "IBM":
                KLArrData = IBM;
                break;
            case "MCD":
                KLArrData = MCD;
                break;
            case "Tesla":
                KLArrData = Tesla;
                break;
            case "XAGUSD":
                KLArrData = XAGUSD;
                break;
            case "SPX500":
                KLArrData = SPX500;
                break;
            case "US30":
                KLArrData = US30;
                break;
            case "NAS100":
                KLArrData = NAS100;
                break;
        }
    }

    // if (KLArrData.length == 0) KLArrData = ETH;
    chart.applyNewData(
      KLArrData.results.map((d) => ({
        close: d.c,
        timestamp: d.t,
      }))
    );

    // 确保图表显示所有数据点
    chart.scrollByDistance(100, 300);
  } catch (e) {
    console.log(e);
  }
}   

// 获取当前时间
function GetDate(){
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
}

// 获取间隔日期
function GetDateByTras(){
    const today = new Date();
    const pastDate = new Date(today);
    pastDate.setDate(today.getDate() - config.Area_Tras);

    const year = pastDate.getFullYear();
    const month = String(pastDate.getMonth() + 1).padStart(2, "0"); // getMonth() 从0开始
    const day = String(pastDate.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
}

//获取k线图数据
async function getKData(TimeData,_type,cancelTokenSource){
    const Arrs = await Server.post(
      "api/market/getKline",
      { ticker: _type, line: TimeData == "fs" ? "15M" : TimeData },
      { cancelToken: cancelTokenSource.token }
    );
    return Arrs;
}

// 绘画k线图
function ViveKLine(_class,_data,chartK,type){
    try{
        if(chartK){
            const style = document.getElementById(_class);
            style.firstChild.remove();
        }
        chartK = init(_class);
        chartK.applyNewData(_data);
        
        if(type == 'fs'){
            chartK.setStyles(sfStyle);
        }else{
            chartK.setStyles(KLineStyle);
        }
        // updateData()
        chartK.setTimezone("Europe/London");
        chartK.scrollByDistance(0, 1);        
        return chartK;
    }catch(e){
        console.log(e)
    }
}

// 更新k线数据
function updateData (chartK,data) {
    if (chartK) {
        chartK.updateData(data);
    }
}

// 更新数据，相同则覆盖，不同则追加
// function applyNewData() {
//     setTimeout(() => {
//         try{
//             chart.applyNewData(getData(), true)
//         }catch(e){
//             console.log(e)
//         }
//     }, 1000)
// }

// 跳转
function SkipPage(item){
    store.commit("SetSymbolData", item);
    this.$router.push({
        name:'TradeIndex',
    })
}

// k线绘画
const KLineStyle = {
  candle: {
    bar: {
      upColor: "#0166fc",
      upBorderColor: "#0166fc",
      upWickColor: "#0166fc",
    },
    tooltip: {
      custom: [
        { title: "High", value: "{high}" },
        { title: "Open", value: "{open}" },
        { title: "Low", value: "{low}" },
      ],
      text: {
        size: 10,
      },
    },
  },
};

// 时分秒绘画
const sfStyle = {
  grid: {
    show: false,
  },
  xAxis: {
    axisLine: {
      show: false,
    },
  },
  yAxis: {
    axisLine: {
      show: false,
    },
  },
  candle: {
    type: "area",
    priceMark: {
      last: {
        line: {
          show: false,
        },
        text: {
          show: false,
        },
      },
    },
    tooltip: {
      custom: [
        { title: "High", value: "{high}" },
        { title: "Open", value: "{open}" },
        { title: "Low", value: "{low}" },
      ],
      text: {
        size: 10,
      },
    },
  },
  crosshair: {
    show: false,
  },
};

export{
    ViveAreaLine,getAreaData,getKData,ViveKLine,updateData
}

export default{
    install(app){
        app.config.globalProperties.$getAreaData=getAreaData;
        app.config.globalProperties.$ViveAreaLine=ViveAreaLine;
        app.config.globalProperties.$SkipPage=SkipPage;
        app.config.globalProperties.$ViveKLine=ViveKLine;
    },
}